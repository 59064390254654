import { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { CgDanger } from 'react-icons/cg';
import { AiOutlineWarning, AiOutlineCheck } from 'react-icons/ai';

const Notification = ({ message, type, dispatch, id, t }) => {
    const [close, setClose] = useState(false);
    const [width, setWidth] = useState(0);
    const [intervalID, setIntervalID] = useState(null);

    const handleStartTimer = () => {
        const id = setInterval(() => {
            setWidth((prev) => {
                if (prev < 100) {
                    return prev + 0.5;
                }

                clearInterval(id);
                return prev;
            });
        }, 20);

        setIntervalID(id);
    };

    const handlePauseTimer = () => {
        clearInterval(intervalID);
    };

    const handleCloseNotification = () => {
        handlePauseTimer();
        setClose(true);
        setTimeout(() => {
            dispatch({
                type: 'REMOVE_NOTIFICATION',
                id: id,
            });
        }, 400);
    };

    useEffect(() => {
        if (width === 100) {
            handleCloseNotification();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    useEffect(() => {
        handleStartTimer();
    }, []);

    return (
        <div
            className={`notification-item notification-${type} notification-${
                close ? 'close' : 'open'
            }`}
            onMouseEnter={handlePauseTimer}
            onMouseLeave={handleStartTimer}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {type === 'success' && (
                    <AiOutlineCheck className={`notification-icon notification-icon-${type}`} />
                )}
                {type === 'warning' && (
                    <AiOutlineWarning className={`notification-icon notification-icon-${type}`} />
                )}
                {type === 'error' && (
                    <CgDanger className={`notification-icon notification-icon-${type}`} />
                )}
                <p className="notification-message">{t(message)}</p>
            </div>

            {/* <div className="notification-bar" style={{ width: `${width}%` }} /> */}
        </div>
    );
};

export default withTranslation()(Notification);

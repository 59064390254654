import React from 'react';
import classnames from 'classnames';

function Icon({ icon: IconComponent, className, size, ...rest }) {
    const classNames = classnames({
        icon: true,
        'icon-s': size === 's',
        'icon-m': size === 'm',
    });

    return (
        <div {...rest} className={classNames + (className ? ` ${className}` : '')}>
            <IconComponent className="icon-svg" />
        </div>
    );
}

Icon.defaultProps = {
    size: 'm',
};

export default Icon;

import { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import { Label, Input, Button, Alert, BounceLoader } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { editCustomer, getCustomerById } from 'features/slices/customers';
import usePrivateApi from '../../../../hooks/usePrivateApi';

const EditCustomerForm = ({ customer: id, setDisplay, t }) => {
    const [editRequestStatus, setEditRequestStatus] = useState('idle');
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const privateApi = usePrivateApi();
    const customer = useSelector(getCustomerById(id));
    const nameRef = useRef();

    useEffect(() => {
        nameRef.current?.focus();
    }, []);

    const formik = useFormik({
        initialValues: {
            id: customer.id,
            name: customer.name,
            status: customer.status,
        },

        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            status: Yup.bool(),
        }),

        onSubmit: async (values) => {
            try {
                setEditRequestStatus('pending');
                await dispatch(editCustomer({ privateApi, customer: values })).unwrap();
                setDisplay(false);
            } catch (err) {
                setError(err.message);
                setEditRequestStatus('idle');
            }
        },
    });

    return (
        <>
            {error && (
                <Alert color="danger" className="fill">
                    {t(error)}
                </Alert>
            )}
            <div className="">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <FocusError formik={formik} />

                    <div className="form-group">
                        <Label htmlFor="name">{t("Nom de l'entité")}</Label>
                        <Input
                            ref={nameRef}
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger mt-5">{t(formik.errors.name)}</div>
                        ) : null}
                    </div>

                    <div className="form-group">
                        <Label htmlFor="status">{t('Status')}</Label>
                        <br />
                        <input
                            type="checkbox"
                            id="status"
                            name="status"
                            style={{ marginLeft: '10px' }}
                            onChange={formik.handleChange}
                            checked={formik.values.status}
                        />
                        {formik.touched.status && formik.errors.status ? (
                            <div className="text-danger mt-5">{t(formik.errors.status)}</div>
                        ) : null}
                    </div>

                    <div className="modal-footer">
                        <div className="modal-actions">
                            <div className="modal-actions-item">
                                <Button
                                    color="danger"
                                    type="button"
                                    outline
                                    onClick={() => {
                                        setDisplay(false);
                                    }}>
                                    {t('Cancel')}
                                </Button>
                            </div>
                            <div className="modal-actions-item">
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={formik.isSubmitting}>
                                    {editRequestStatus === 'pending' ? (
                                        <BounceLoader color="white" />
                                    ) : (
                                        t('Save')
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default withTranslation()(EditCustomerForm);

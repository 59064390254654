import { withTranslation } from 'react-i18next';
import { Modal, Card, CardTitle, CardBody } from 'components';
import UsersTable from './UsersTable';
import ModulesTable from './ModulesTable';
import { useSelector } from 'react-redux';
import { getEnvironment } from 'features/slices/customers';
import CreditsTable from './CreditsTable';

const DetailsEnvironmentDialog = ({
    customer: customerId,
    environment: environmentId,
    display,
    setDisplay,
    t,
}) => {
    const environment = useSelector(getEnvironment(customerId, environmentId));

    return (
        <Modal display={display} setDisplay={setDisplay}>
            <div style={{ maxWidth: '700px' }}>
                <Card>
                    <CardBody>
                        <Card>
                            <CardTitle>{t('Users')}</CardTitle>
                            <CardBody>
                                <UsersTable users={environment.users} />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardTitle>{t('Modules')}</CardTitle>
                            <CardBody>
                                <ModulesTable modules={environment.modules} />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardTitle>{t('Credits')}</CardTitle>
                            <CardBody>
                                <CreditsTable environment={environment} />
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
};

export default withTranslation()(DetailsEnvironmentDialog);

import PropTypes from 'prop-types';
import classnames from 'classnames';

const Alert = ({ className, color, children, ...rest }) => {
    const classNames = classnames('alert', `alert-${color}`, className);
    return (
        <button className={classNames} {...rest}>
            {children}
        </button>
    );
};

Alert.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'warning', 'danger', 'info']),
    closable: PropTypes.bool,
};

Alert.defaultProps = {
    color: 'primary',
};

export default Alert;

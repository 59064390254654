import React, { useReducer, createContext } from 'react';
import { Notification } from 'components';

const NotificationContext = createContext({});

export const NotificationProvider = (props) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'ADD_NOTIFICATION':
                return [...state, { ...action.payload }];
            case 'REMOVE_NOTIFICATION':
                return state.filter((notif) => notif.id !== action.id);
            default:
                return state;
        }
    }, []);

    return (
        <NotificationContext.Provider value={dispatch}>
            <div className="notification-wrapper">
                {state.map((notif) => {
                    return <Notification dispatch={dispatch} key={notif.id} {...notif} />;
                })}
            </div>
            {props.children}
        </NotificationContext.Provider>
    );
};

export default NotificationContext;

import PropTypes from 'prop-types';
import classnames from 'classnames';

const Button = ({ className, color, outline, rounded, fill, children, ...rest }) => {
    const classNames = classnames(
        className,
        'btn',
        `btn-${outline ? `outline-` : ''}${color}`,
        rounded ? 'btn-rounded' : '',
        fill ? 'btn-fill' : '',
    );
    return (
        <button className={classNames} {...rest}>
            {children}
        </button>
    );
};

Button.propTypes = {
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'warning',
        'danger',
        'info',
        'link',
        'dark',
        'light',
    ]),
    outline: PropTypes.bool,
    rounded: PropTypes.bool,
};

Button.defaultProps = {
    color: 'primary',
    outline: false,
    rounded: false,
    fill: false,
};

export default Button;

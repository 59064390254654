import { useRef, useEffect } from 'react';
import classnames from 'classnames';

function Dropdown({ className, display, setDisplay, children, ...rest }) {
    const dropdownRef = useRef();
    const classNames = classnames(className, 'dropdown');

    useEffect(() => {
        const handleCloseDropdown = (e) => {
            if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
                setDisplay(!display);
            }
        };

        if (display) {
            window.addEventListener('click', handleCloseDropdown);
        }

        return () => {
            window.removeEventListener('click', handleCloseDropdown);
        };
    }, [display, setDisplay]);

    return display ? (
        <div className={classNames} ref={dropdownRef} {...rest}>
            {children}
        </div>
    ) : null;
}

export default Dropdown;

import { withTranslation } from 'react-i18next';
import { Badge, Table, TableHead } from 'components';

const ModulesTable = ({ modules, t }) => {
    const columns = [
        { id: 'publisher', alignment: 'center', text: 'Publisher' },
        { id: 'crm', alignment: 'center', text: 'Crm' },
        { id: 'timesheet', alignment: 'center', text: 'Timesheet' },
        { id: 'tax', alignment: 'center', text: 'Fiscalité' },
        { id: 'bookkeeping', alignment: 'center', text: 'Bookkeeping' },
        { id: 'workflow', alignment: 'center', text: 'Workflow' },
    ];

    return (
        <Table>
            <TableHead columns={columns} />
            <tbody>
                <tr>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color={modules.publisher ? 'success' : 'danger'}>
                            {t(modules.publisher ? 'Yes' : 'No')}
                        </Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color={modules.crm ? 'success' : 'danger'}>
                            {t(modules.crm ? 'Yes' : 'No')}
                        </Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color={modules.timesheet ? 'success' : 'danger'}>
                            {t(modules.timesheet ? 'Yes' : 'No')}
                        </Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color={modules.tax ? 'success' : 'danger'}>
                            {t(modules.tax ? 'Yes' : 'No')}
                        </Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color={modules.bookkeeping ? 'success' : 'danger'}>
                            {t(modules.bookkeeping ? 'Yes' : 'No')}
                        </Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color={modules.workflow ? 'success' : 'danger'}>
                            {t(modules.workflow ? 'Yes' : 'No')}
                        </Badge>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default withTranslation()(ModulesTable);

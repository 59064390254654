import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Input = forwardRef((props, ref) => {
    const { className, type, ...rest } = props;

    const classNames = classnames(
        className,
        'form-control',
        type === 'color' ? 'form-control-color' : '',
    );
    return <input ref={ref} type={type} className={classNames} {...rest} />;
});

Input.propTypes = {
    type: PropTypes.oneOf([
        'text',
        'number',
        'password',
        'datetime-local',
        'date',
        'time',
        'color',
    ]),
};

Input.defaultProps = {
    type: 'text',
};

export default Input;

import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationFR from './fr';
import translationEN from './en';
import translationDE from './de';

const resources = {
    fr: {
        translation: translationFR,
    },
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
};
const language = localStorage.getItem('language');
if (!language) {
    localStorage.setItem('language', 'fr');
}

i18n.use(detector)
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('language') || 'fr',
        fallbackLng: 'fr',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;

import PropTypes from 'prop-types';
import classnames from 'classnames';

const Select = ({ className, options, nullable, ...rest }) => {
    const classNames = classnames(className, 'form-select');

    return (
        <>
            <select className={classNames} {...rest}>
                {nullable && <option value=""></option>}
                {options &&
                    options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
            </select>
        </>
    );
};

Select.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    nullable: PropTypes.bool,
};

Select.defaultProps = {
    options: [],
    nullable: true,
};

export default Select;

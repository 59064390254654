import { Badge, Table, TableHead } from 'components';

const UsersTable = ({ environment }) => {
    const columns = [
        { id: 'nb-allowed-folders', alignment: 'center', text: 'Folders number' },
        { id: 'nb-compartments', alignment: 'center', text: 'Compartments number' },
    ];

    return (
        <Table>
            <TableHead columns={columns} />
            <tbody>
                <tr>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color="info">{environment.nbAllowedFolders}</Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color="info">{environment.nbCompartments}</Badge>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default UsersTable;

import React from 'react';
import classnames from 'classnames';

function ButtonIcon({ icon: IconComponent, color, className, round, size, ...rest }) {
    const classNames = classnames({
        'btn-icon': true,
        'btn-icon-round': round,
        'btn-icon-danger': color === 'danger',
        'btn-icon-s': size === 's',
        'btn-icon-m': size === 'm',
    });

    return (
        <button {...rest} className={classNames + (className ? ` ${className}` : '')}>
            <IconComponent className={`btn-icon-svg text-${color}`} />
        </button>
    );
}

ButtonIcon.defaultProps = {
    round: true,
    size: 'm',
};

export default ButtonIcon;

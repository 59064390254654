const BounceLoader = ({ color }) => {
    return (
        <div className="bounce-container">
            <div className="bounce">
                <div className={`bounce1 bg-${color}`} />
                <div className={`bounce2 bg-${color}`} />
                <div className={`bounce3 bg-${color}`} />
            </div>
        </div>
    );
};

export default BounceLoader;

import { useContext } from 'react';
import NotificationContext from 'context/NotificationProvider';
import { v4 as uuid } from 'uuid';

const useNotification = () => {
    const dispatch = useContext(NotificationContext);

    return (props) => {
        dispatch({
            type: 'ADD_NOTIFICATION',
            payload: {
                id: uuid(),
                ...props,
            },
        });
    };
};

export default useNotification;

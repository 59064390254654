import { withTranslation } from 'react-i18next';
import { Modal, Card, CardTitle, CardBody } from 'components';
import CreateEnvironmentForm from './CreateEnvironmentForm';

const CreateEnvironmentDialog = ({
    customer,
    display,
    setDisplay,
    setDisplayEnvironments,
    callback,
    t,
}) => {
    return (
        <Modal display={display} setDisplay={setDisplay}>
            <div style={{ maxWidth: '800px' }}>
                <Card>
                    <CardTitle>{t('Create an environment')}</CardTitle>
                    <CardBody>
                        <CreateEnvironmentForm
                            customer={customer}
                            callback={callback}
                            setDisplay={setDisplay}
                            setDisplayEnvironments={setDisplayEnvironments}
                        />
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
};

export default withTranslation()(CreateEnvironmentDialog);

import MetaTags from 'react-meta-tags';
import { withTranslation } from 'react-i18next';
import LoginForm from './LoginForm';
import { LanguageButton } from 'components';
import ineditLogo from 'assets/images/inedit/in-edit-dark.png';

const Login = ({ t }) => {
    return (
        <>
            <MetaTags>
                <title>{t('Authentication')} | In-edit</title>
            </MetaTags>

            <div className="auth-container">
                <img src={ineditLogo} alt="in-edit" width={210} className="auth-logo" />
                <div className="auth-form">
                    <div className="auth-form-header">
                        <LanguageButton />
                    </div>
                    <LoginForm />
                </div>
            </div>
        </>
    );
};

export default withTranslation()(Login);

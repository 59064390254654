const CubeLoader = () => {
    return (
        <div className="loader-cube-container">
            <div className="loader-cube1 loader-cube" />
            <div className="loader-cube2 loader-cube" />
            <div className="loader-cube4 loader-cube" />
            <div className="loader-cube3 loader-cube" />
        </div>
    );
};

export default CubeLoader;

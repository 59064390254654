export const sortArrayByProps = (array, props, orderBy) =>
    array.slice().sort((a, b) => {
        let aProps = a[props];
        let bProps = b[props];

        if (typeof aProps === 'string') {
            aProps = aProps.toLowerCase();
        }

        if (typeof bProps === 'string') {
            bProps = bProps.toLowerCase();
        }

        if (aProps < bProps) return orderBy === 'desc' ? 1 : -1;
        if (aProps > bProps) return orderBy === 'desc' ? -1 : 1;
        return 0;
    });

export const sortArrayDatasetByProps = (array, props, orderBy) => {
    array.sort((a, b) => {
        if (a.dataset[props] < b.dataset[props]) return orderBy === 'desc' ? 1 : -1;
        if (a.dataset[props] > b.dataset[props]) return orderBy === 'desc' ? -1 : 1;
        return 0;
    });
};

export const filterArray = (array, key, search) => {
    return array.filter((option) =>
        option[key]
            ? option[key].toString().toLowerCase().indexOf(search.toLowerCase()) > -1
            : false,
    );
};

export const getLabelByValue = (options, value) => {
    for (const option of options) {
        if (option.value === value) {
            return option.label;
        }
    }
    return '';
};

import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Table, TableHead } from 'components';
import { useSelector } from 'react-redux';
import { getFilteredEnvironments } from 'features/slices/customers';

import EnvironmentRow from './EnvironmentRow';

const EnvironmentsTable = ({ customer: customerId, t }) => {
    const environments = useSelector(getFilteredEnvironments(customerId));

    const columns = [
        { id: 'id', alignment: 'left', text: 'Environment' },
        { id: 'productKey', alignment: 'left', text: 'Product key' },
        { id: 'expiration-date', alignment: 'center', text: 'Expiration date' },
        { id: 'update', alignment: 'center', text: 'Last update' },
        { id: 'details', alignment: 'center', text: 'Details' },
        { id: 'actions', alignment: 'right', text: 'Actions' },
    ];

    return environments?.length > 0 ? (
        <Table>
            <TableHead columns={columns} />
            <tbody>
                {environments.map((environment) => (
                    <EnvironmentRow
                        key={environment.id}
                        customer={customerId}
                        environment={environment.id}
                    />
                ))}
            </tbody>
        </Table>
    ) : (
        <p className="text-danger">{t('No environments to display')}</p>
    );
};

EnvironmentsTable.propTypes = {
    environments: PropTypes.array.isRequired,
};

EnvironmentsTable.defaultProps = {
    environments: [],
};

export default withTranslation()(EnvironmentsTable);

import classnames from 'classnames';

const Table = ({ className, children, ...rest }) => {
    const classNames = classnames('table', className);
    return (
        <table className={classNames} {...rest}>
            {children}
        </table>
    );
};

export default Table;

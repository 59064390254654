import { withTranslation } from 'react-i18next';
import { Modal, Card, CardTitle, CardBody } from 'components';
import KeyGenForm from './KeyGenForm';

const KeyGenDialog = ({ customer, environment, display, setDisplay, t }) => {
    return (
        <Modal display={display} setDisplay={setDisplay}>
            <div style={{ width: '450px' }}>
                <Card>
                    <CardTitle>{t('Generate product key')}</CardTitle>
                    <CardBody>
                        <KeyGenForm
                            customer={customer}
                            environment={environment}
                            setDisplay={setDisplay}
                        />
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
};

export default withTranslation()(KeyGenDialog);

import { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCustomers,
    getAllCustomers,
    getCustomersError,
    getCustomersSearch,
    getCustomersStatus,
    changeSearch,
} from 'features/slices/customers';
import { Badge, ButtonIcon, CubeLoader, Input } from 'components';
import { IoAddOutline, IoReload } from 'react-icons/io5';
import useAuthorization from 'hooks/useAuthorization';
import CustomersTable from './CustomersTable/CustomersTable';
import CreateCustomerDialog from './CustomersTable/CreateCustomerDialog/CreateCustomerDialog';
import usePrivateApi from '../../hooks/usePrivateApi';

const Crm = ({ t }) => {
    const [showCreateCustomerDialog, setShowCreateCustomerDialog] = useState(false);

    const dispatch = useDispatch();
    const privateApi = usePrivateApi();
    const isAuthorized = useAuthorization();
    const customers = useSelector(getAllCustomers);
    const status = useSelector(getCustomersStatus);
    const error = useSelector(getCustomersError);
    const search = useSelector(getCustomersSearch);

    useEffect(() => {
        if (status === 'idle') dispatch(fetchCustomers({ privateApi }));
    }, [status, dispatch]);

    let content;
    if (status === 'loading') {
        content = (
            <div style={{ marginTop: '100px' }}>
                <CubeLoader />
            </div>
        );
    } else if (status === 'succeeded') {
        content = <CustomersTable />;
    } else if (status === 'failed') {
        content = <div>{error}</div>;
    }

    return (
        <>
            <MetaTags>
                <title>{t('Crm')} | In-edit</title>
            </MetaTags>

            <>
                <div className="container">
                    <div className="crm">
                        <div className="crm-header">
                            <div className="crm-title">
                                <span className="title">{t('Customers')}</span>
                                <span className="crm-title-count">
                                    <Badge color="info" rounded>
                                        {customers.length}
                                    </Badge>
                                </span>
                            </div>
                            <div className="crm-actions">
                                <div className="action-wrapper">
                                    <div className="action-item action-item-right">
                                        <ButtonIcon
                                            icon={IoReload}
                                            disabled={status === 'loading'}
                                            onClick={() => dispatch(fetchCustomers({ privateApi }))}
                                        />
                                    </div>
                                </div>

                                {isAuthorized('CUSTOMER_MANAGER') && (
                                    <div className="action-wrapper">
                                        <div className="action-item action-item-right">
                                            <ButtonIcon
                                                icon={IoAddOutline}
                                                onClick={() => setShowCreateCustomerDialog(true)}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="action-wrapper action-wrapper-right">
                                    <div className="action-item">
                                        <div style={{ width: '300px' }}>
                                            <Input
                                                placeholder={`${t('Search')}...`}
                                                value={search}
                                                onChange={(e) =>
                                                    dispatch(
                                                        changeSearch({ search: e.target.value }),
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {content}
                    </div>
                </div>
            </>

            <>
                <CreateCustomerDialog
                    display={showCreateCustomerDialog}
                    setDisplay={setShowCreateCustomerDialog}
                />
            </>
        </>
    );
};

export default withTranslation()(Crm);

import { Badge, Table, TableHead } from 'components';

const UsersTable = ({ users }) => {
    const columns = [
        { id: 'standard', alignment: 'center', text: 'Standard' },
        { id: 'ts', alignment: 'center', text: 'Timesheet' },
        { id: 'ts-r', alignment: 'center', text: 'Timesheet (R)' },
        { id: 'ts-rw', alignment: 'center', text: 'Timesheet (R/W)' },
    ];

    return (
        <Table>
            <TableHead columns={columns} />
            <tbody>
                <tr>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color="info">{users.standard}</Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color="info">{users.timesheet}</Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color="info">{users.timesheetRead}</Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color="info">{users.timesheetReadWrite}</Badge>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default UsersTable;

import authentication from './authentication.json';
import error from './error.json';
import crm from './crm.json';
import global from './global.json';

export default {
    ...authentication,
    ...error,
    ...crm,
    ...global
}
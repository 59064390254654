import { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'locales/i18n';
import { withTranslation } from 'react-i18next';
import languages from 'locales/languages';

import { Dropdown, Button } from 'components';

const LanguageButton = ({ color }) => {
    const [display, setDisplay] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'));

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
        setSelectedLanguage(language);
        setDisplay(false);
    };

    return (
        <>
            <Button color={color} type="button" onClick={() => setDisplay(!display)}>
                <img src={languages[selectedLanguage].flag} alt="flag" height="16" />
                <Dropdown
                    display={display}
                    setDisplay={setDisplay}
                    className="dropdown-slide mt-10">
                    {Object.keys(languages).map((language, index) => (
                        <div
                            key={index}
                            onClick={() => changeLanguage(language)}
                            className="dropdown-item">
                            <img
                                src={languages[language].flag}
                                alt="flag"
                                className="mr-9"
                                height="12"
                            />
                            {languages[language].label}
                        </div>
                    ))}
                </Dropdown>
            </Button>
        </>
    );
};

LanguageButton.propTypes = {
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'warning',
        'danger',
        'info',
        'link',
        'dark',
        'light',
    ]),
};

LanguageButton.defaultProps = {
    color: 'light',
};

export default withTranslation()(LanguageButton);

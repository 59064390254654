import React, { useEffect, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring';

const modalsNode = document.getElementById('modals');

function Modal({ display, setDisplay, children }) {
    const modalRef = useRef();
    const animation = useSpring({
        config: {
            duration: 220,
        },
        opacity: display ? 1 : 0,
        transform: display ? `translateY(0%)` : `translateY(-100%)`,
    });

    const closeModal = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setDisplay(false);
        }
    };

    const keyPress = useCallback(
        (e) => {
            if (e.key === 'Escape' && display) {
                setDisplay(false);
            }
        },
        [setDisplay, display],
    );

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => {
            document.removeEventListener('keydown', keyPress);
        };
    }, [keyPress]);

    return display
        ? ReactDOM.createPortal(
              <div onMouseDown={closeModal} className="modal-background">
                  <animated.div style={animation} className="modal-container">
                      <div ref={modalRef}>{children}</div>
                  </animated.div>
              </div>,
              modalsNode,
          )
        : null;
}

export default Modal;

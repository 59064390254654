import { useDispatch } from 'react-redux';
import { login } from 'features/slices/auth';
import api from 'api';

const useRefreshAuth = () => {
    const dispatch = useDispatch();

    const refresh = async () => {
        const res = await api.get('/security/refresh', {
            withCredentials: true,
        });

        const { user, roles, accessToken } = res?.data;
        dispatch(login({ user, roles, accessToken }));

        return accessToken;
    };
    return refresh;
};

export default useRefreshAuth;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Dropdown, ButtonIcon, ConfirmDialog } from 'components';
import { AiOutlineEllipsis } from 'react-icons/ai';
import { BiListUl } from 'react-icons/bi';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCustomer, getCustomerById } from 'features/slices/customers';
import useAuthorization from 'hooks/useAuthorization';
import CreateEnvironmentDialog from './CreateEnvironmentDialog/CreateEnvironmentDialog';
import EnvironmentsTable from '../EnvironmentsTable/EnvironmentsTable';
import EditCustomerDialog from './EditCustomerDialog/EditCustomerDialog';
import usePrivateApi from '../../../hooks/usePrivateApi';

const CustomerRow = ({ customer: id, t }) => {
    const [showDropdownActions, setShowDropdownActions] = useState(false);
    const [showAddEnvironmentDialog, setShowAddEnvironmentDialog] = useState(false);
    const [showEditCustomerDialog, setShowEditCustomerDialog] = useState(false);
    const [showDeleteCustomerDialog, setShowDeleteCustomerDialog] = useState(false);
    const [showEnvironments, setShowEnvironments] = useState(false);

    const dispatch = useDispatch();
    const privateApi = usePrivateApi();
    const customer = useSelector(getCustomerById(id));
    const isAuthorized = useAuthorization();

    const toggleShowActions = () => {
        setShowDropdownActions((prev) => !prev);
    };

    const toggleShowEnvironments = () => {
        setShowEnvironments((prev) => !prev);
    };

    return (
        <>
            <tr>
                <td>
                    <ButtonIcon
                        icon={BiListUl}
                        size="s"
                        disabled={customer?.environments?.length === 0}
                        onClick={toggleShowEnvironments}
                    />
                </td>
                <td>{customer?.id}</td>
                <td>{customer?.name}</td>
                <td style={{ textAlign: 'center' }}>
                    <Badge color={customer?.status ? 'success' : 'danger'}>
                        {t(customer?.status ? 'Activé' : 'Désactivé')}
                    </Badge>
                </td>
                <td style={{ textAlign: 'center' }}>{customer?.environments?.length}</td>
                <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {isAuthorized('CUSTOMER_EDITOR', 'CUSTOMER_MANAGER') && (
                        <div className="relative">
                            <ButtonIcon
                                icon={AiOutlineEllipsis}
                                size="s"
                                onClick={toggleShowActions}
                            />
                            <Dropdown
                                className="dropdown-slide"
                                display={showDropdownActions}
                                setDisplay={setShowDropdownActions}>
                                <div style={{ width: '250px' }}>
                                    <div
                                        className="dropdown-item"
                                        onClick={() => {
                                            toggleShowActions();
                                            setShowAddEnvironmentDialog(true);
                                        }}>
                                        <span>{t('Add an environment')}</span>
                                    </div>
                                    <div
                                        className="dropdown-item"
                                        onClick={() => {
                                            toggleShowActions();
                                            setShowEditCustomerDialog(true);
                                        }}>
                                        <span>{t('Edit this customer')}</span>
                                    </div>
                                    {isAuthorized('CUSTOMER_MANAGER') && (
                                        <div
                                            className="dropdown-item"
                                            onClick={() => {
                                                toggleShowActions();
                                                setShowDeleteCustomerDialog(true);
                                            }}>
                                            <span className="text-danger">
                                                {t('Delete this customer')}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </Dropdown>
                        </div>
                    )}
                </td>
            </tr>

            <>
                <CreateEnvironmentDialog
                    customer={customer.id}
                    callback={() => setShowEnvironments(true)}
                    display={showAddEnvironmentDialog}
                    setDisplay={setShowAddEnvironmentDialog}
                    setDisplayEnvironments={setShowEnvironments}
                />
                <EditCustomerDialog
                    customer={customer.id}
                    display={showEditCustomerDialog}
                    setDisplay={setShowEditCustomerDialog}
                />
                <ConfirmDialog
                    display={showDeleteCustomerDialog}
                    setDisplay={setShowDeleteCustomerDialog}
                    message="Are you sure you want to delete this customer ?"
                    callback={() => dispatch(deleteCustomer({ privateApi, id: customer.id }))}
                />
                {showEnvironments && customer.environments.length > 0 && (
                    <tr>
                        <td colSpan={6}>
                            <div style={{ margin: '2px 10px 25px 20px' }}>
                                <EnvironmentsTable customer={id} />
                            </div>
                        </td>
                    </tr>
                )}
            </>
        </>
    );
};

CustomerRow.propTypes = {
    customer: PropTypes.number.isRequired,
};

export default withTranslation()(CustomerRow);

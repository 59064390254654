import { useDispatch } from 'react-redux';
import { logout } from 'features/slices/auth';
import api from 'api';

const useLogout = () => {
    const dispatch = useDispatch();

    const disconnect = async () => {
        try {
            await api.get('/auth/logout', {
                withCredentials: true,
            });
            dispatch(logout());
        } catch (err) {
            console.error(err);
        }
    };

    return disconnect;
};

export default useLogout;

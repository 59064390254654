import frenchFlag from 'assets/images/flags/french.jpg';
import usFlag from 'assets/images/flags/us.jpg';
import germanyFlag from 'assets/images/flags/germany.jpg';

const languages = {
    fr: {
        label: 'Français',
        flag: frenchFlag,
    },
    en: {
        label: 'English',
        flag: usFlag,
    },
    de: {
        label: 'Deutsch',
        flag: germanyFlag,
    },
};

export default languages;

import { withTranslation } from 'react-i18next';
import { Modal, Card, CardTitle, CardBody } from 'components';
import CreateCustomerForm from './CreateCustomerForm';

const CreateCustomerDialog = ({ display, setDisplay, t }) => {
    return (
        <Modal display={display} setDisplay={setDisplay}>
            <div style={{ width: '400px' }}>
                <Card>
                    <CardTitle>{t('Create a customer')}</CardTitle>
                    <CardBody>
                        <CreateCustomerForm setDisplay={setDisplay} />
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
};

export default withTranslation()(CreateCustomerDialog);

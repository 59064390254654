import { useSelector } from 'react-redux';

const useAuthorization = () => {
    const auth = useSelector((state) => state?.auth);

    const isAuthorized = (...allowedRoles) =>
        !!auth?.roles
            .map((role) => ['SUPERUSER', ...allowedRoles].includes(role))
            .find((val) => val === true);

    return isAuthorized;
};

export default useAuthorization;
